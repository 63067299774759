import React from 'react';
import { Link } from 'gatsby';
import Icon from './Icon';

// TODO replace inline styles
const Card = ({ icon, title, description, link }) => (
  <div style={{ display: 'flex', paddingTop: '20px' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Icon
        iconName={icon}
        style={{ fontSize: '80px', paddingRight: '20px', color: '#e2e2e2' }}
      />
    </div>
    <div className="">
      <h2>
        <Link to={link}>{title}</Link>
      </h2>
      <p>
        {description}
        {'. '}
        <Link to={link}>Mehr</Link>
      </p>
    </div>
  </div>
);

export default Card;
